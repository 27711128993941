import React from "react";
import { useNavigate } from "react-router-dom";

const ContentFour = () => {
  return (
    <div className="contentFour text-center">
      <button>
        <a href="https://bit.ly/3QMaLp0" target="_blank">
          BUY NOW
        </a>
      </button>
    </div>
  );
};

export default ContentFour;
