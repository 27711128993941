import React from "react";
import ContentOne from "./ContentOne";
import ContentTwo from "./ContentTwo";
import ContentThree from "./ContentThree";
import ContentFour from "./ContentFour";
import ContentFive from "./ContentFive";
import ContentSix from "./ContentSix";
import ContentSeven from "./ContentSeven";
import ContentEight from "./ContentEight";
import ContentNine from "./ContentNine";

const index = () => {
  return (
    <div>
      <ContentOne />
      <ContentSix />
      <ContentNine />
      <ContentFour />
      <ContentTwo />
      {/* <ContentThree /> */}
      <ContentEight />
      <ContentFive />
      <ContentFour />
      <ContentSeven />
    </div>
  );
};

export default index;
