import React from "react";
import ReactPlayer from "react-player";
import G1 from "../assets/g1.jpeg";
import G2 from "../assets/g2.jpeg";
import G3 from "../assets/g3.jpeg";
import G4 from "../assets/g4.jpeg";
import G5 from "../assets/g5.jpeg";
import G6 from "../assets/g6.jpeg";
import RajaBook1Intro from "../assets/RajaBook1Intro.mp4";

import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";

const ContentEight = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <div className="px-5 contentEight">
      <h1 className="text-center mb-5">GALLERY</h1>
      <Row>
        <Col md={12} className="d-flex justify-content-center mb-5">
          <ReactPlayer
            className="react-player"
            url={RajaBook1Intro}
            playing={false}
            width="60%"
            height="100%"
            controls={true}
          />
        </Col>
      </Row>
      <Row>
        <Slider {...settings}>
          <div>
            <img src={G1} width="100%" height="600px" />
          </div>
          <div>
            <img src={G3} width="100%" height="200px" />
            <img src={G4} width="100%" height="200px" />
            <img src={G5} width="100%" height="200px" />
          </div>
          <div>
            <img src={G2} width="100%" height="600px" />
          </div>
          <div>
            <img src={G6} width="100%" height="600px" />
          </div>
        </Slider>
      </Row>
    </div>
  );
};

export default ContentEight;
