import React from "react";
import amazon from "../assets/amazon.png";

const ContentNine = () => {
  return (
    <div>
      <div className="booksAvail text-center mt-5 mb-2">
        <h2>Books are available on</h2>
        <img className="mt-5 mb-5" src={amazon} width="10%" />
      </div>
    </div>
  );
};

export default ContentNine;
