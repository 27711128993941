import React from "react";
import { Row, Col } from "react-bootstrap";
import Raj3 from "../assets/raj3.png";
import BooksAvail from "../assets/booksAvail.png";

const ContentTwo = () => {
  return (
    <>
      <Row className="mt-2 contentTwo">
        <Col className="mt-5" md={5}>
          <small>RAJA VENKATESWAR</small>
          <h1 className="mb-0">Who is</h1>
          <h1>Raja ?</h1>
          <p className="mt-5 mb-0">
            My friends tell me that the one thing that defines me is my
            insatible curiosity. I am intensely curious and love the whole
            process of learning. This has probably defined my life the most
            which has been a great roller coaster ride meandering through four
            leading global institutions, through a dozen countries where I have
            worked or consulted in and a further forty odd where I traveled for
            work or pleasure.
          </p>
          <button>LEARN MORE</button>
          <a href="#link">CHECK MY WORKS</a>
        </Col>
        <Col md={7}>
          <img src={Raj3} width="100%" />
        </Col>
      </Row>
    </>
  );
};

export default ContentTwo;
