import React from "react";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const ContentSeven = () => {
  const author = () => {
    const element = document.querySelector(".contentTwo");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const events = () => {
    const element = document.querySelector(".contentSix");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const gallery = () => {
    const element = document.querySelector(".contentEight");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const contact = () => {
    const element = document.querySelector(".contentSeven");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="contentSeven">
      {/* <Row>
        <Col md={6} sm={6} xs={6} className="comeAlong px-5">
          <h5 className="mb-3">COME ALONG</h5>
          <p className="mt-3">
            Accompany me on my exciting journey from stay-at-home mom to
            published author. Subscribe to my monthly newsletter and get the
            first 2 chapters of Only Child.
          </p>
          <Form.Group
            as={Row}
            className="mt-4 mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="" />
            </Col>
          </Form.Group>
          <div className="text-center">
            <button>SUBMIT</button>
          </div>
        </Col>
        <Col md={6} sm={6} xs={6} className="px-5 contactUs">
          <h5 className="mb-3">CONTACT US</h5>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Name
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" placeholder="" />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Email
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" placeholder="" />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Phone
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" placeholder="" />
              </Col>
            </Form.Group>
          </Form>
          <div className="text-center">
            <button>SUBMIT</button>
          </div>
        </Col>
      </Row> */}
      <hr></hr>
      <div className="d-flex justify-content-center footerImages">
        <a
          href="https://www.facebook.com/profile.php?id=61553045262973&mibextid=ViGcVu"
          target="_blank"
          className="text-end"
        >
          <img src={facebook} width="10%" />
        </a>
        &nbsp;
        <a
          href="https://instagram.com/bookbyrajavenkateswar?igshid=MTNiYzNiMzkwZA=="
          target="_blank"
        >
          <img src={instagram} width="10%" />
        </a>
      </div>
      <br />
      <div className="d-flex justify-content-center footerMenu">
        <p onClick={author}>About Author</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p onClick={events}>Events</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p onClick={gallery}>Gallery</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p onClick={contact}>Contact</p>
      </div>
    </div>
  );
};

export default ContentSeven;
