import React from "react";
import { Row, Col } from "react-bootstrap";
import TableBook from "../assets/tableBook.png";
import TableBook1 from "../assets/tableBook1.JPG";
import Carousel from "react-bootstrap/Carousel";

const ContentFive = () => {
  return (
    <Carousel>
      <Carousel.Item interval={2000}>
        <Row className="contentFive">
          <Col md={5}>
            <img src={TableBook1} width="80%" height="300px" />
          </Col>
          <Col md={7}>
            <h1 className="mb-5">ORDER BOOKS NOW</h1>
            <h5 className="mb-3">
              The author delves into his cinematic memories, reflecting on films
              that deeply impacted him. He combines these insights to his own
              experiences in management, dissecting real-life case studies to
              unveil unconventional yet invaluable lessons, often absent from
              the conventional boardroom curriculum.
            </h5>
            <p>
              Through 21 chapters, the author analyses critical attributes for
              enlightened leadership, people and team development, creating a
              culture of excellence - all written with deep personal insights
              and industry case studies using Indian movies as a metaphor.
            </p>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Row className="contentFive">
          <Col md={5}>
            <img src={TableBook} />
          </Col>
          <Col md={7}>
            <h1 className="mb-5">ORDER BOOKS NOW</h1>
            <h5 className="mb-3">
              This book unravels the author's personal reflections on his
              formative teenage years and the shared dream of a nation in
              progress.
            </h5>
            <p>
              It’s a work of fiction but then all works of fiction are often
              based in reality or other works or mythologies which themselves
              have a base in some reality! Even Tolkien, of the hobbit forming
              variety, was influenced by Celtic and Gaelic mythology. As it is
              with this book. Unlike Gaelic and Celtic, this book is influenced
              by people | met, the places | went to, the experiences | had (some
              real and most imagined) that transformed me and many of the
              characters in this little book; often positively and sustainably.
            </p>
          </Col>
        </Row>
      </Carousel.Item>
    </Carousel>
  );
};

export default ContentFive;
