import { React, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Hall1 from "../assets/hall1.jpg";
import Hall2 from "../assets/hall2.jpg";
import Events from "../assets/events.jpeg";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const ContentSix = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="contentSix">
      <h2 className="text-center">UPCOMING EVENTS</h2>
      <Carousel className="eventCarousel mt-5">
        <Carousel.Item interval={1000}>
          <Row>
            <Col md={6}>
              <img src={Events} width="100%" />
            </Col>
            <Col md={6} className="px-5">
              <h2 className="mt-5">Memories, Movies & Management</h2>
              <p className="mt-5">
                In Memories, Movies & Management, the author delves into his
                cinematic memories, reflecting on films that deeply impacted
                him. He combines these insights to his own experiences in
                management, dissecting real-life case studies to unveil
                unconventional yet invaluable lessons, often absent from the
                conventional boardroom curriculum.{" "}
              </p>
              {/* <button className="regButton mt-5" onClick={handleShow}>
                Register Now
              </button> */}
              <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                  <iframe
                    src="https://link.cdp360.com/widget/form/wJPCq8Vj4uPyGTltTaWp"
                    // style="width:100%;height:100%;border:none;border-radius:3px"
                    width="100%"
                    height="500px"
                    id="inline-wJPCq8Vj4uPyGTltTaWp"
                    data-layout="{'id':'INLINE'}"
                    data-trigger-type="alwaysShow"
                    data-trigger-value=""
                    data-activation-type="alwaysActivated"
                    data-activation-value=""
                    data-deactivation-type="neverDeactivate"
                    data-deactivation-value=""
                    data-form-name="Jaipur Book Launch Event"
                    data-height="576"
                    data-layout-iframe-id="inline-wJPCq8Vj4uPyGTltTaWp"
                    data-form-id="wJPCq8Vj4uPyGTltTaWp"
                    title="Jaipur Book Launch Event"
                  ></iframe>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
      {/* <Row className="mt-5 mb-5">
        <Col md={3}>
          <img src={Hall1} width="100%" />
        </Col>
        <Col md={6}>
          <span>Seattle, WA - </span>
          <span>Benatoya Hall</span>
          <h3>An Evening with David Sedaris</h3>
          <p>
            It’s a work of fiction but then all works of fiction are often based
            in reality or other works or mythologies which themselves have a
            base in some reality!
          </p>
        </Col>
        <Col md={3} className="text-end">
          <p>Nov</p>
          <h1>20</h1>
        </Col>
      </Row>
      <hr></hr>
      <Row className="mt-5 mb-5">
        <Col md={3}>
          <img src={Hall2} width="100%" />
        </Col>
        <Col md={6}>
          <span>Potchogue, NY - </span>
          <span>Potchogue Theatre</span>
          <h3>America's Pre-Eminent Humor Writer</h3>
          <p>
            It’s a work of fiction but then all works of fiction are often based
            in reality or other works or mythologies which themselves have a
            base in some reality!
          </p>
        </Col>
        <Col md={3} className="text-end">
          <p>Apr</p>
          <h1>03</h1>
        </Col>
      </Row> */}
    </div>
  );
};

export default ContentSix;
