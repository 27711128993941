import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import bgImage from "../assets/bgImage.png";
import varBook from "../assets/varBook.png";
import bolBook from "../assets/bolBook.png";
import just_launched from "../assets/just_launched.png";
import coming_soon from "../assets/coming_soon.png";

import Carousel from "react-bootstrap/Carousel";

const ContentOne = () => {
  const author = () => {
    const element = document.querySelector(".contentTwo");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const events = () => {
    const element = document.querySelector(".contentSix");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const gallery = () => {
    const element = document.querySelector(".contentEight");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const contact = () => {
    const element = document.querySelector(".contentSeven");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <div className="redColor">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="navMenu my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link className="fw-bold">BLOGS</Nav.Link>
                <Nav.Link className="fw-bold" onClick={events}>
                  EVENTS
                </Nav.Link>
                <Navbar.Brand className="fw-bold" href="#">
                  RAJA VENKATESWAR
                </Navbar.Brand>
                <Nav.Link className="fw-bold" onClick={author}>
                  ABOUT AUTHOR
                </Nav.Link>
                <Nav.Link className="fw-bold" onClick={gallery}>
                  GALLERY
                </Nav.Link>
                {/* <Nav.Link href="#action2">EDITOR'S CHOICE</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Row className="bgImage">
        <Col md={6} className="bookColImg">
          <img src={varBook} />
        </Col>
        <Col md={6} className="bookColContent">
          <div className="content d-flex">
            <div>
              <h1 className="mb-0">AROUND</h1>
              <h1 className="mb-0">VARANASI IN</h1>
              <h1>ASSI DAYS</h1>
              <p>
                Step into the transformative world of 90s India, where change
                blossoms through the power of embracing experiences. Join the
                author on an enchanting journey
              </p>
              <a href="https://bit.ly/3QMaLp0" target="_blank">
                <button>Learn More</button>
              </a>
            </div>
            <div>
              <img src={coming_soon} width="100%" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="bgImage1">
        <Col md={6} className="bookColImg">
          <img src={bolBook} />
        </Col>
        <Col md={6} className="bookColContent">
          <div className="content d-flex">
            <div>
              <h1 className="mb-0">MEMORIES</h1>
              <h1 className="mb-0">MOVIES &</h1>
              <h1>Management</h1>
              <p>
                In Memories, Movies & Management, the author delves into his
                cinematic memories, reflecting on films that deeply impacted
                him.
              </p>
              <a href="https://bit.ly/3QMaLp0" target="_blank">
                <button>Learn More</button>
              </a>
            </div>
            <div>
              <img src={just_launched} width="100%" />
            </div>
          </div>
        </Col>
      </Row>

      {/* <Carousel>
        <Carousel.Item interval={5000} className="bgImage1">
       
        </Carousel.Item>
        <Carousel.Item interval={5000} className="bgImage">
          <Row>
            <Col md={12}>
          
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel> */}
    </>
  );
};

export default ContentOne;
